import React from "react";
// import { Officers } from './list'
import officer1 from "../../img/1BFARINLOYE.webp";
import officer2 from "../../img/2SAKINTAN.webp";
import officer3 from "../../img/3OKUKU.webp";
import officer4 from "../../img/4ODENNIS.webp";
import officer5 from "../../img/5EOLUPITAN.webp";
import officer6 from "../../img/6JAMUSU.webp";
import officer7 from "../../img/7W.AINABE.webp";
import officer8 from "../../img/8EADEBIYI.webp";
import officer9 from "../../img/9ANDUPU.webp";
import officer10 from "../../img/10AAGIRI.webp";
import officera from "../../img/DBIU.webp";
import officerb from "../../img/SAJENGE.webp";
import president from "../../img/president.webp";
import governor from "../../img/governor.webp";

const Officials = () => {
  return (
    <div>
      <div className=" bg-primary py-1  md:w-[30vw] px-2 md:px-0"> </div>
      {/* <h1 className=" text-center py-10 px-10 md:px-0 lg:py-20 text-[28px] lg:text-[48px] text-black font-bold">
        Special Marshal Officials
      </h1> */}
      <div className=" flex justify-end">
        <div className=" bg-primary py-1 mt-2 lg:mb-20 md:w-[30vw]"> </div>
      </div>
      <div className="container mx-auto">
        <div className=" text-center px-4 !lg:px-0 flex flex-col items-center justify-center mb-4">
          <img
            src={president}
            className="w-full lg:w-[350px] h-[350px] object-cover"
            alt=""
          />
          <div className="bg-primary w-full lg:w-[350px] py-4 bg-[#0067FF] text-white">
            <p className="text-[16px] text-white text-center uppercase">
              <span className="font-semibold">His Excellency</span>
              <br />
              President Bola Ahmed Tinubu
              <br />
              Commander-in-chief of the Nigerian Armed Forces
            </p>
          </div>
        </div>
        <div className=" text-center px-4 !lg:px-0 flex flex-col items-center justify-center mb-4">
          <img
            src={governor}
            className="w-full lg:w-[350px] h-[350px] object-cover"
            alt=""
          />
          <div className="bg-primary w-full lg:w-[350px] py-4 bg-[#0067FF] text-white">
            <p className="text-[16px] text-white text-center uppercase">
              <span className="font-semibold">Governor Babajide Sanwo-Olu</span>
              <br />
              Governor of lagos state
              <br />
            </p>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row justify-center gap-4 mb-4">
          <div className=" text-center px-4 !lg:px-0">
            <img
              src={officera}
              className="w-full lg:w-[350px] h-[350px] object-cover"
              alt=""
            />
            <div className="bg-primary w-full lg:w-[350px] py-4 bg-[#0067FF] text-white">
              <p className="text-[16px] font-semibold text-white text-center">
                D BIU
              </p>
              <p className=" text-white text-center">CORPS MARSHAL</p>
            </div>
          </div>

          {/* <div className=" text-center px-4 !lg:px-0">
            <img
              src={officerb}
              className="w-full lg:w-[350px] h-[350px] object-cover"
              alt=""
            />
            <div className="bg-primary w-full lg:w-[350px] py-4 bg-[#0067FF] text-white">
              <p className="text-[16px] font-semibold text-white text-center">
                S AJENGE
              </p>
              <p className=" text-white text-center">DEPUTY CORPS MARSHAL</p>
            </div>
          </div> */}
        </div>
        <div className=" px-4 md:px-0 grid gap-4 md:grid-cols-3 lg:grid-cols-4 md:gap-4 lg:gap-6 mb-5">
          {/* <div className=" text-center">
            <img src={officer1} className="w-full" alt="" />
            <div className="bg-primary w-full py-4 bg-[#0067FF] text-white">
              <p className="text-[16px] font-semibold text-white text-center">
                CC BABATUNDE O. FARINLOYE
              </p>
              <p className=" text-white text-center">
                THE SECTOR COMMANDER RS 2.1 LAGOS SECTOR COMMAND
              </p>
            </div>
          </div> */}

          <div className=" text-center">
            <img src={officer2} className="w-full" alt="" />
            <div className="bg-primary w-full py-4 bg-[#0067FF] text-white">
              <p className="text-[16px] font-semibold text-white text-center">
                SM. OLUSEGUN AKINTAN
              </p>
              <p className=" text-white text-center">
                THE STATE COORDINATOR SPECIAL MARSHALS RS 2.1 LAGOS
              </p>
            </div>
          </div>

          <div className=" text-center">
            <img src={officer3} className="w-full" alt="" />
            <div className="bg-primary w-full py-4 bg-[#0067FF] text-white">
              <p className="text-[16px] font-semibold text-white text-center">
                SM. OLALEKAN KUKU
              </p>
              <p className=" text-white text-center">
                THE DEPUTY STATE COORDINATOR SPECIAL MARSHALS RS 2.1 LAGOS
              </p>
            </div>
          </div>

          <div className=" text-center">
            <img src={officer4} className="w-full" alt="" />
            <div className="bg-primary w-full py-4 bg-[#0067FF] text-white">
              <p className="text-[16px] font-semibold text-white text-center">
                SM. OLABISI O. DENNIS
              </p>
              <p className=" text-white text-center">
                THE STATE SECRETARY SPECIAL MARSHALS RS 2.1 LAGOS
              </p>
            </div>
          </div>
          <div className=" text-center">
            <img src={officer5} className="w-full" alt="" />
            <div className="bg-primary w-full py-4 bg-[#0067FF] text-white">
              <p className="text-[16px] font-semibold text-white text-center">
                SM. EMMANUEL I. OLUPITAN
              </p>
              <p className=" text-white text-center">
                THE STATE TREASURER SPECIAL MARSHALS RS 2.1 LAGOS
              </p>
            </div>
          </div>

          {/* <div className=" text-center">
            <img src={officer6} className="w-full" alt="" />
            <div className="bg-primary w-full py-4 bg-[#0067FF] text-white">
              <p className="text-[16px] font-semibold text-white text-center">
                SM. JIMMY AMUSU
              </p>
              <p className=" text-white text-center">
                THE STATE PUBLIC ENLIGHTENMENT OFFICER SPECIAL MARSHALS RS 2.1
                LAGOS
              </p>
            </div>
          </div> */}

          <div className=" text-center">
            <img src={officer7} className="w-full" alt="" />
            <div className="bg-primary w-full py-4 bg-[#0067FF] text-white">
              <p className="text-[16px] font-semibold text-white text-center">
                SM. WILLIAM AINABE
              </p>
              <p className=" text-white text-center">
                THE STATE WELFARE OFFICER SPECIAL MARSHALS RS 2.1 LAGOS
              </p>
            </div>
          </div>
          <div className=" text-center">
            <img src={officer8} className="w-full" alt="" />
            <div className="bg-primary w-full py-4 bg-[#0067FF] text-white">
              <p className="text-[16px] font-semibold text-white text-center">
                SM. EMMANUEL O. ADEBIYI
              </p>
              <p className=" text-white text-center">
                THE STATE FINANCIAL SECRETARY SPECIAL MARSHALS RS 2.1 LAGOS
              </p>
            </div>
          </div>

          <div className=" text-center">
            <img src={officer9} className="w-full" alt="" />
            <div className="bg-primary w-full py-4 bg-[#0067FF] text-white">
              <p className="text-[16px] font-semibold text-white text-center">
                SM. ALEX NDUPU
              </p>
              <p className=" text-white text-center">
                THE STATE PROVOST OFFIICER SPECIAL MARSHALS RS 2.1 LAGOS
              </p>
            </div>
          </div>

          <div className=" text-center">
            <img src={officer10} className="w-full" alt="" />
            <div className="bg-primary w-full py-4 bg-[#0067FF] text-white">
              <p className="text-[16px] font-semibold text-white text-center">
                SM. ADELEYE AGIRI
              </p>
              <p className=" text-white text-center">
                THE ASSISTANT STATE SECRETARY SPECIAL MARSHALS RS 2.1 LAGOS
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className=" bg-primary py-1 mt-2 mb-20 md:w-[30vw]"> </div>
    </div>
  );
};

export default Officials;
