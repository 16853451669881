import React from "react";
import heroImage from "../../img/hero-img.svg";
import heroBg1 from "../../img/heroBg1.png";
import logo from "../../img/frsc-logo.webp";
import coat from "../../img/coatofarm.webp";
function index() {
  const Bg1 = {
    backgroundImage: `url(${heroBg1})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };
  return (
    <section id="slider" className=" ">
      <div className=" text-white pt-8 md:pt-0 bg-[#0067FF] ">
        <div className=" container mx-auto bg-[#0067FF] text-center h-[450px] flex items-center justify-center">
          <div className="flex justify-center items-start gap-4">
            <img
              alt=""
              src={coat}
              width="150"
              height="150"
              className="hidden md:block"
            />
            <div className="px-2">
              <h1 className=" text-[33px] lg:text-[50px] mb-8 lg:mb-4 font-semibold leading-[35px] lg:leading-[45px]">
                FEDERAL ROAD SAFETY CORPS <br />
                <span className="text-[20px] lg:text-[30px]">RS. 2.1 LAGOS SECTOR COMMAND</span>
              </h1>
              <p className="text-[20px] lg:text-[30px] font-medium lg:font-semibold">SPECIAL MARSHAL PORTAL</p>
              <p className=" mt-2 lg:mt-4 text-[18px] lg:text-[24px]">
                Our Motto: We say, we do.
              </p>
            </div>
            <img
              alt=""
              src={logo}
              width="150"
              height="150"
              className="hidden md:block"
            />
          </div>
          {/* <p className=" text-[15px] mt-2 "> The volunteer arm of the Federal Road Safety Corps </p> */}
          {/* <div className=" mt-3 grid md:flex gap-6 md:gap-0 flex-wrap justify-center ">figma <button className=" text-black bg-white text-[20px] border-2 border-white font-semibold py-2 px-[50px]"> <a href="../login" className=" text-blue-500"> Login </a> </button> <button className=" text-white border-2 border-white bg-primary text-[20px] font-semibold py-2 px-4 md:ml-4"> Look Up Marshal </button> </div> */}
        </div>
      </div>
      <div style={Bg1} className="mt-[-30px] hidden lg:block pb-1">
        <img src={heroImage} alt="hero" className="w-full " />
      </div>
    </section>
  );
}
export default index;
